import React from "react";
import Header from "../components/header/header.js";
import ReadMore from "../components/readMore/readMore.js";

const Hospitality = () => {
  return (
    <>
      <Header
        title="La Suite Hospitality"
        metaDescription="Con noi le recensioni positive saranno assicurate, insieme ad un aumento della brand reputation!"
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <h2>
              Con noi le recensioni positive saranno assicurate, insieme ad un
              aumento della brand reputation!
            </h2>
            <p className="description">
              <br />
              <br />
              Offri al tuo cliente un servizio trasversale di côncierge che lo
              accompagnerà dal momento della prenotazione sino al check-out,
              offrendo anche la possibilità di checkIN online.
              <br />
              <br />
              Attira i clienti e fidelizzali sfruttando il WiFi e i suoi
              strumenti di promozione e comunicazione.
              <br />
              <br />
              Offri una navigazione sicura, e un’assistenza durante il
              soggiorno; sarai in grado di monitorare l’umore del tuo ospite e
              di far fronte alle sue esigenze.
            </p>
            <div className="section" />
          </div>
        </div>
        <div className="columns invert">
          <div className="column">
            <p className="description">
              <b>Book@Me</b> è un <b>booking engine</b> con
              <b> channel manager integrato in cloud</b> che ti consente di
              ricevere prenotazioni dirette senza commissioni,
              <b>abbattendo costi per il tuo business</b>, e di gestire tutti i
              tuoi canali web grazie al channel manager, 
              <b>evitando così il rischio di overbooking.</b>
              <br /> Grazie al <b>checkIN online</b> facilita l’operatività del
              receptionist e consente al tuo cliente di registrarsi ovunque esso
              si trovi!
            </p>
            <div className="section" />
            <ReadMore to="http://www.bookatme.cloud/" text="Scopri di più" externalLink={true} />
          </div>
          <div className="column is-offset-2">
          <img src="https://static.acmeproduzioni.it/bookatme/logo.svg" alt="Logo Book@Me"  />
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column">
          <img src="https://static.acmeproduzioni.it/eucleia/logo.svg" alt="Logo Eucleia"  />
          </div>
          <div className="column is-offset-2">
            <p className="description">
              <b>Eucleia</b> è un <b>software in cloud che consente di migliorare la tua reputazione online </b>
              e di abbattere di oltre il 70% le recensioni negative in modo
              semplice, attraverso l’invio automatico al tuo cliente di mail e
              sms.
              <br />
              Lo sapevi che la soddisfazione del tuo cliente genera pubblicità?
              Sin dal primo click crea con esso un rapporto privilegiato.
              <br />
              <b>Mission della tua attività: un cliente soddisfatto!</b>
            </p>
            <div className="section" />
            <ReadMore to="https://eucleia.app/" text="Scopri di più" externalLink={true} />
          </div>
        </div>
        <div className="section" />
      </div>
    </>
  );
};

export default Hospitality;
